import type { RootState } from '~/reducers';

import { getWeddingPlannerDetails } from '../vendorStorefrontSelectors';

export const getIsPlanningLevelComplete = (state: RootState, planningLevelKey: string): boolean => {
  const { storefrontDetails } = state.vendorStorefront;
  const weddingPlannerDetails = getWeddingPlannerDetails(state, storefrontDetails?.id as number);

  const pckge = weddingPlannerDetails?.packages?.find(
    (singlePackage) => singlePackage?.serviceLevelFacet?.key === planningLevelKey
  );

  if (!pckge) {
    // if a package doesn't exist yet set to required
    return true;
  }
  // if the package exist check for the required fields
  if (pckge.packageOptions?.length && pckge.storefrontPriceView?.minCents) {
    return false;
  }
  return true;
};

export const getIsServiceLevelsAndPricingSectionComplete = (state: RootState) => {
  const { storefrontDetails } = state.vendorStorefront;
  const weddingPlannerDetails = getWeddingPlannerDetails(state, storefrontDetails?.id as number);
  const pckges = weddingPlannerDetails?.packages;

  if (!pckges || pckges.length === 0) return false;

  return (
    pckges.filter(
      (pckge) => pckge?.packageOptions?.length === 0 || !pckge?.storefrontPriceView?.minCents
    ).length === 0
  );
};
