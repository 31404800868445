import { PriceRangeFieldsProps } from '~/pages/vendors/Storefront/editPages/VendorPricing/inputs/PriceRangeFieldsV2';
import { VendorPricingSection } from '~/pages/vendors/Storefront/editPages/VendorPricing/vendorPricingFormTypes';
import { getVendorPricing } from '~/pages/vendors/Storefront/editPages/VendorPricing/vendorPricingSections';
import type { RootState } from '~/reducers';

/**
 * Returns if pricing information is complete for a storefront.
 *
 * @param {*} state
 *
 * @see src/pages/vendors/Storefront/editPages/BasicInfoV2/vendorPricingMeta.js
 */

export const getIsPricingInfoComplete = (state: RootState): boolean => {
  return getIsAverageSpendSectionComplete(state) && getIsDefinePricingSectionComplete(state);
};

export const getIsPricingInfoInProgress = (state: RootState): boolean => {
  return getIsAverageSpendSectionInProgress(state) && getIsDefinePricingSectionInProgress(state);
};
const getAverageSpendSection = (
  state: RootState,
  predicate: (values: (number | null)[]) => boolean
) => {
  const { storefrontDetails } = state.vendorStorefront;

  if (!storefrontDetails) return false;

  const { targetCoupleBudgetMax, targetCoupleBudgetMin } = storefrontDetails;

  return predicate([targetCoupleBudgetMax, targetCoupleBudgetMin]);
};

export function getIsAverageSpendSectionComplete(state: RootState) {
  return getAverageSpendSection(state, (values) =>
    values.every((value) => typeof value === 'number')
  );
}
export function getIsAverageSpendSectionInProgress(state: RootState) {
  return getAverageSpendSection(state, (values) =>
    values.some((value) => typeof value === 'number')
  );
}

const getDefinePricingSection = (
  state: RootState,
  predicate: (values: VendorPricingSection) => boolean
) => {
  const { storefrontDetails } = state.vendorStorefront;

  if (!storefrontDetails) return false;
  const vendorPricingFields = getVendorPricing(storefrontDetails.taxonomyKey);

  return predicate(vendorPricingFields);
};

const isFieldComplete = (state: RootState, field: VendorPricingSection['fields'][number]) => {
  if (
    field.Component !== 'AtLeastOneField' &&
    field.Component !== 'PriceRangeFields' &&
    field.Component !== 'SinglePriceField'
  ) {
    return true;
  }
  if (field.Component === 'AtLeastOneField') {
    return field.props.some((prop) => typeof prop.selector(state) === 'number');
  }
  if (!(field.props as PriceRangeFieldsProps).minimum?.required) {
    return true;
  }

  if (field.Component === 'SinglePriceField') {
    return typeof field.props.selector(state) === 'number';
  }

  if (field.Component === 'PriceRangeFields') {
    return typeof field.props.minimum.selector(state) === 'number';
  }

  return true;
};

export function getIsDefinePricingSectionComplete(state: RootState) {
  return getDefinePricingSection(state, (vendorPricingFields) =>
    vendorPricingFields.fields.reduce<boolean>((isComplete, field) => {
      if (isComplete === false) return false;
      return isFieldComplete(state, field);
    }, true)
  );
}

export function getIsDefinePricingSectionInProgress(state: RootState) {
  return getDefinePricingSection(state, (vendorPricingFields) =>
    vendorPricingFields.fields.some((field) => {
      return isFieldComplete(state, field);
    })
  );
}
