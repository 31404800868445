import _isEmpty from 'lodash/isEmpty';

import type { RootState } from '~/reducers';

import { getCurrentVendorDetails } from '../vendorStorefrontSelectors';

const getBioSection = (
  state: RootState,
  predicate: (values: (string | number | null | undefined)[]) => boolean
) => {
  const vendor = getCurrentVendorDetails(state);
  if (!vendor || !('name' in vendor && 'bioPhotoImageId' in vendor)) {
    return false;
  }

  const { description, setsMeApart } = state.vendorStorefront.storefrontDetails || {};
  const { name, bioPhotoImageId } = vendor;

  return predicate([description, name, bioPhotoImageId, setsMeApart]);
};

export const getBioSectionComplete = (state: RootState) => {
  return getBioSection(state, (values) => values.every((field) => !_isEmpty(field)));
};
export const getBioSectionInProgress = (state: RootState) => {
  return getBioSection(state, (values) => values.some((field) => !_isEmpty(field)));
};
