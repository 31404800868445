import { createSelector } from 'reselect';

import type { RootState } from '~/reducers';
import type { StorefrontPreferredVendorEntry } from '~/reducers/storefrontPreferredVendorsReducer';
import { numerical } from '~/util/sortUtils';

/* Selectors */
export const getPreferredVendors = createSelector(
  (state: RootState) => state.preferredVendors.allIds,
  (state: RootState) => state.preferredVendors.byId,
  (allIds, byId): StorefrontPreferredVendorEntry[] => {
    return [...allIds].sort(numerical).map((id) => byId[id]);
  }
);

export const getHasPreferredVendors = createSelector(
  getPreferredVendors,
  (preferredVendors) => preferredVendors.length > 0
);
