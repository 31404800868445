import {
  BANDS_DJS_TAXONOMY_KEY,
  BAR_SERVICES_TAXONOMY_KEY,
  CAKES_DESSERTS_TAXONOMY_KEY,
  CATERING_TAXONOMY_KEY,
  FLORISTS_TAXONOMY_KEY,
  HAIR_MAKEUP_TAXONOMY_KEY,
  PHOTOGRAPHERS_TAXONOMY_KEY,
  PLANNERS_TAXONOMY_KEY,
  VENUES_TAXONOMY_KEY,
  VIDEOGRAPHERS_TAXONOMY_KEY,
} from '@zola-helpers/client/dist/es/marketplace/vendorTaxonomyKeys';
import { getVendorTaxonomySwitchFunc } from '@zola-helpers/client/dist/es/marketplace/vendorUtils';
import { isAfterDate, parseDate } from '@zola-helpers/client/dist/es/util/dateUtils';

import { createSelector } from 'reselect';

import t from '~/meta/vendorTerms';
import { OptionalSection, StorefrontSections } from '~/pages/vendors/Storefront/types';
import type { RootState } from '~/reducers';
import { getHasPackages } from '~/selectors/packagesSelectors';
import {
  getIsPricingInfoComplete,
  getIsAverageSpendSectionComplete,
  getIsDefinePricingSectionComplete,
  getIsPricingInfoInProgress,
  getIsAverageSpendSectionInProgress,
  getIsDefinePricingSectionInProgress,
} from '~/selectors/vendorPricingSelectorsV2';
import { getAreRealWeddingsComplete } from '~/selectors/vendors/vendorWeddingSelectors';
import featureFlags from '~/util/featureFlags';
import { isCategoryWithOptionalPortfolioSection } from '~/util/vendorUtils';

import Logger from '../util/logger';
import { getHasPreferredVendors } from './storefrontPreferredVendorsSelectors';
import { getAreReviewRequestsComplete } from './vendorReviewsSelectors';
import { getIsBasicInfoComplete, getIsBasicInfoInProgress } from './vendors/basicInfoSelectors';
import { getBioSectionComplete, getBioSectionInProgress } from './vendors/bioSectionSelectors';
import {
  getIsPlanningLevelComplete,
  getIsServiceLevelsAndPricingSectionComplete,
} from './vendors/plannerSelectors';
import {
  getAreServicesComplete,
  getAreSubmittedReviewsComplete,
  getAreVenueDetailsComplete,
  getHasExtraCosts,
  getHasMenus,
  getHasSpaces,
  getHasStories,
  getHasVideoGallery,
  getIsBarServicesOnly,
  getIsCoverGalleryComplete,
  getIsCoverGalleryInProgress,
  getIsFaqSectionComplete,
  getIsFaqSectionInProgress,
  getIsPortfolioComplete,
  getIsPortfolioInProgress,
  getIsPublished,
  getIsVideoComplete,
  getVendorTaxonomyKey,
} from './vendorStorefrontSelectors';

export const getCoverImagesSection = createSelector(
  getIsCoverGalleryComplete,
  getIsCoverGalleryInProgress,
  (isCoverGalleryComplete, isCoverGalleryInProgress): StorefrontSections => ({
    key: 'add-cover-images',
    title: 'Cover Images',
    requiredToPublish: true,
    linkTo: '/inspire/vendors/listing/cover-images',
    hasContent: isCoverGalleryComplete,
    completionContribution: (taxonomyId: number) => {
      return taxonomyId === 1 ? 5 : 10;
    },
    isInProgress: isCoverGalleryInProgress,
  })
);

export const getBasicInfoSection = createSelector(
  getIsBasicInfoComplete,
  getIsBasicInfoInProgress,
  (isBasicInfoComplete, isBasicInfoInProgress): StorefrontSections => {
    return {
      key: 'your-basic-info',
      requiredToPublish: true,
      linkTo: '/inspire/vendors/listing/basic-information',
      hasContent: isBasicInfoComplete,
      completionContribution: 5.0,
      isInProgress: isBasicInfoInProgress,
      title: 'Business Info',
    };
  }
);

const getVendorPricingSection = createSelector(
  getIsPricingInfoComplete,
  getIsPricingInfoInProgress,
  (isVendorPricingComplete, isInProgress): StorefrontSections => {
    return {
      key: 'your-pricing',
      requiredToPublish: true,
      linkTo: '/inspire/vendors/listing/pricing',
      hasContent: isVendorPricingComplete,
      title: 'Pricing',
      completionContribution: 5.0,
      isInProgress,
    };
  }
);

const getPortfolioSection = createSelector(
  getVendorTaxonomyKey,
  getIsPortfolioComplete,
  getIsPortfolioInProgress,
  (vendorTaxonomyKey, isPortfolioComplete, isInProgress): StorefrontSections => ({
    key: 'build-your-portfolio',

    title: 'Photo Gallery',

    requiredToPublish: !isCategoryWithOptionalPortfolioSection(vendorTaxonomyKey),
    linkTo: '/inspire/vendors/listing/portfolio',
    hasContent: isPortfolioComplete,

    completionContribution: [BANDS_DJS_TAXONOMY_KEY, VENUES_TAXONOMY_KEY].includes(
      vendorTaxonomyKey
    )
      ? 5
      : 10,
    isInProgress,
  })
);

const VENUE_VIDEO_SECTION_META = {
  title: 'Videos and Virtual Tours',
  heading: 'Have a video or virtual tour? Enter their IDs below.',
  instructions:
    'Give couples an idea of what it’s like to be inside your venue. We support videos and files hosted on the sites below.',
};

const getVideoSectionMeta = createSelector(getVendorTaxonomyKey, (taxonomyKey) => {
  switch (taxonomyKey) {
    case VENUES_TAXONOMY_KEY:
      return VENUE_VIDEO_SECTION_META;
    case PHOTOGRAPHERS_TAXONOMY_KEY:
      return {
        title: 'Video',
        heading: 'Couples love seeing who is behind the camera.',
        instructions:
          'Add your Vimeo or Youtube ID to show couples what it’s like to work with you.',
      };
    case BANDS_DJS_TAXONOMY_KEY:
      return {
        requiredToPublish: true,

        title: 'Highlight Reel',
        heading: 'Add a short video that showcases what you do best',
        instructions:
          'Help couples get to know you, your style, and your work. We support videos hosted on Vimeo and YouTube.',
      };
    case VIDEOGRAPHERS_TAXONOMY_KEY:
      return {
        title: 'Highlight Reel',
        heading: 'Add a short video that showcases what you do best',
        instructions:
          'Help couples get to know you, your style, and your work. We support videos hosted on Vimeo and YouTube.',
      };
    case CATERING_TAXONOMY_KEY:
    case BAR_SERVICES_TAXONOMY_KEY:
    case CAKES_DESSERTS_TAXONOMY_KEY:
    case FLORISTS_TAXONOMY_KEY:
    case PLANNERS_TAXONOMY_KEY:
      return null;
    default:
      Logger.error(`Unsupported vendor type in videoSectionMeta: ${taxonomyKey}`);
      return VENUE_VIDEO_SECTION_META;
  }
});

const getVideoSection = createSelector(
  getVideoSectionMeta,
  getIsVideoComplete,
  (videoSectionMeta, isVideoComplete): StorefrontSections => {
    return {
      ...{
        key: 'your-videos-and-tours',

        linkTo: '/inspire/vendors/listing/video',
        hasContent: isVideoComplete,
        completionContribution: 5.0,
      },
      ...(videoSectionMeta as OptionalSection),
      isInProgress: isVideoComplete,
    };
  }
);
// This is in local time, not utc - 3 month time span added a couple of days for release
const NEW_TAG_END = parseDate('2023-05-19');

const isNewPeriod = (): boolean => {
  const now = new Date();
  return isAfterDate(now, NEW_TAG_END);
};

const getBadgesSection = createSelector(
  getIsPublished,
  (isPublished): StorefrontSections | null => {
    if (isPublished) {
      return {
        key: 'your-badge',

        title: 'Badges and awards',

        requiredToPublish: false,
        linkTo: '/inspire/vendors/listing/badges',
        hasContent: false, // always show as link
        completionContribution: 0,
        tag: !isNewPeriod() ? 'New' : null,
      };
    }
    return null;
  }
);

const getBioSection = createSelector(
  getBioSectionComplete,
  getVendorTaxonomyKey,
  getBioSectionInProgress,
  (isBioSectionComplete, taxonomyKey, isInProgress): StorefrontSections => ({
    key: 'your-bio',
    title: t(taxonomyKey, 'editListingPage.bioSectionTitle', 'Bio'),

    requiredToPublish: true,
    linkTo: '/inspire/vendors/listing/bio',
    hasContent: isBioSectionComplete,
    completionContribution: 10,
    isInProgress,
  })
);

const getMarketSection = createSelector(
  getVendorTaxonomyKey,
  (taxonomyKey): StorefrontSections => ({
    key: 'your-markets',
    title: t(taxonomyKey, 'editListingPage.marketSectionTitle', 'Markets'),

    requiredToPublish: true,
    linkTo: '/inspire/vendors/listing/markets',
    hasContent: true,
    completionContribution: 10,
    isInProgress: false,
  })
);

const getServicesSection = createSelector(
  getAreServicesComplete,
  (areServicesComplete): StorefrontSections => ({
    key: 'your-services',
    title: 'Services You Provide',
    requiredToPublish: true,
    linkTo: '/inspire/vendors/listing/services',
    hasContent: areServicesComplete,
    completionContribution: (taxonomyId: number) => (taxonomyId === 4 ? 10.0 : 5.0),
    isInProgress: areServicesComplete,
  })
);

const getPreferredVendorsSection = createSelector(
  getHasPreferredVendors,
  (hasPreferredVendors): StorefrontSections => ({
    key: 'your-preferred-vendors',
    title: 'Preferred Vendors',
    linkTo: '/inspire/vendors/listing/preferred-vendors',
    hasContent: hasPreferredVendors,
    completionContribution: 10.0,
    isInProgress: hasPreferredVendors,
  })
);

const getStoriesSection = createSelector(
  getHasStories,
  (hasStories): StorefrontSections => ({
    key: 'your-catered-wedding-stories',

    title: 'Sample Catered Weddings',

    linkTo: '/inspire/vendors/listing/sample-catered-weddings',
    hasContent: hasStories,
    requiredToPublish: false,

    completionContribution: 10.0,
  })
);

const getSpacesSection = createSelector(
  getHasSpaces,
  (hasSpaces): StorefrontSections => ({
    key: 'your-rooms-and-spaces',

    title: 'Rooms and Event Spaces',

    linkTo: '/inspire/vendors/listing/rooms',
    hasContent: hasSpaces,
    requiredToPublish: true,

    completionContribution: 5.0,
    isInProgress: hasSpaces,
  })
);

const getAnnouncementsSection = createSelector(
  getIsPublished,
  (isPublished): StorefrontSections | null => {
    if (isPublished && featureFlags.get('enableVendorAnnouncements')) {
      return {
        key: 'announcements',

        title: 'Announcements',

        requiredToPublish: false,
        linkTo: '/inspire/vendors/listing/announcements',
        hasContent: false, // always show as link
        completionContribution: 0,
        tag: 'New',
      };
    }
    return null;
  }
);

const getReviewsSection = createSelector(
  getAreReviewRequestsComplete,
  getAreSubmittedReviewsComplete,
  (areReviewRequestsComplete, areSubmittedReviewsComplete): StorefrontSections => {
    const hasContent = areReviewRequestsComplete || areSubmittedReviewsComplete;
    return {
      key: 'your-requested-reviews',
      title: 'Reviews',

      requiredToPublish: true,
      linkTo: '/inspire/vendors/request-reviews',
      hasContent,

      completionContribution: 10.0,
      isInProgress: hasContent,
    };
  }
);

const getFaqSection = createSelector(
  getIsFaqSectionComplete,
  getIsFaqSectionInProgress,
  (isFaqSectionComplete, isInProgress): StorefrontSections => ({
    key: 'your-faqs',
    title: 'FAQs',

    linkTo: '/inspire/vendors/listing/faqs',
    hasContent: isFaqSectionComplete,
    isInProgress,
    requiredToPublish: false,
    completionContribution: 5.0,
  })
);

const getPackagesSection = createSelector(
  getHasPackages,
  (hasPackages): StorefrontSections => ({
    key: 'your-packages',

    title: 'Packages',

    linkTo: '/inspire/vendors/listing/packages',
    hasContent: hasPackages,

    completionContribution: 5.0,
  })
);

const getExtraCostsSection = createSelector(
  getHasExtraCosts,
  (hasExtraCosts): StorefrontSections => ({
    key: 'your-extra-costs',

    title: 'Extra Costs',

    linkTo: '/inspire/vendors/listing/extra-costs',
    hasContent: hasExtraCosts,

    completionContribution: 5.0,
  })
);

/* Video Gallery */
const getVideoGallerySectionMeta = createSelector(getVendorTaxonomyKey, (taxonomyKey) => {
  switch (taxonomyKey) {
    case BANDS_DJS_TAXONOMY_KEY:
      return {
        title: 'Performance Samples',
        heading: 'Give couples a closer look at your work and the types of performances you offer.',

        requiredToPublish: false,
      };
    case VIDEOGRAPHERS_TAXONOMY_KEY:
      return {
        title: 'Video Samples',
        heading:
          'Give couples a closer look at your work and the types of wedding videos you offer.',

        requiredToPublish: true,
      };
    default:
      return null;
  }
});
export const getVideoGallerySection = createSelector(
  getVideoGallerySectionMeta,
  getHasVideoGallery,
  (videoGallerySectionMeta, hasVideoGallery): StorefrontSections => ({
    key: 'video-gallery',

    linkTo: '/inspire/vendors/listing/video-gallery',
    hasContent: hasVideoGallery,
    completionContribution: 10.0,

    title: 'Video Samples',
    isInProgress: hasVideoGallery,
    ...videoGallerySectionMeta,
  })
);

const getServiceLevelsAndPricingSection = createSelector(
  getIsServiceLevelsAndPricingSectionComplete,
  (serviceLevelsAndPricingComplete) => {
    return {
      key: 'planning-levels-and-pricing',

      title: 'Planning Levels & Pricing',
      hasContent: serviceLevelsAndPricingComplete,
      linkTo: '/inspire/vendors/listing/planning-levels-and-pricing',
      requiredToPublish: true,
      completionContribution: 10.0,
    };
  }
);

const getRealWeddingsSubmissionSection = createSelector(
  getAreRealWeddingsComplete,
  (isRealWeddingsComplete): StorefrontSections => ({
    key: 'real-weddings-submission',

    title: 'Real Weddings',

    linkTo: '/inspire/vendors/listing/real-weddings',
    hasContent: isRealWeddingsComplete,
    requiredToPublish: false,
    restrictedToPublished: true,

    completionContribution: 10.0,
  })
);

// TODO: surely there's a simpler way of doing this
const getStorefrontSectionsVenue = createSelector(
  getBadgesSection,
  getBasicInfoSection,
  getVendorPricingSection,
  getAnnouncementsSection,
  getCoverImagesSection,
  getPortfolioSection,
  getVideoSection,
  getAreVenueDetailsComplete,
  getSpacesSection,
  getPackagesSection,
  getHasMenus,
  getServicesSection,
  getPreferredVendorsSection,
  getReviewsSection,
  getFaqSection,
  getIsPublished,
  (
    badgesSection,
    basicInfoSection,
    vendorPricingSection,
    announcementsSection,
    coverImageSection,
    portfolioSection,
    videoSection,
    areVenueDetailsComplete,
    spacesSection,
    packagesSection,
    hasMenus,
    servicesSection,
    perferredVendorsSection,
    reviewsSection,
    faqSection,
    isPublished
  ): StorefrontSections[] => {
    const defaultProps = {
      requiredToPublish: false,
      hasContent: false,
    };

    const sections: StorefrontSections[] = [
      coverImageSection,
      basicInfoSection,
      vendorPricingSection,
      ...(announcementsSection ? [announcementsSection] : []),
      portfolioSection,
      videoSection,
      {
        key: 'your-venue-details',

        title: 'Venue Details',

        requiredToPublish: true,
        linkTo: '/inspire/vendors/listing/about-venue',
        hasContent: areVenueDetailsComplete,
        completionContribution: 5.0,
        isInProgress: areVenueDetailsComplete,
      },
      spacesSection,
      packagesSection,
      {
        key: 'your-menus',
        title: 'Menus',

        linkTo: '/inspire/vendors/listing/menus',
        hasContent: hasMenus,
        completionContribution: 5.0,
      },
      servicesSection,
      perferredVendorsSection,
      reviewsSection,
      {
        ...faqSection,
        requiredToPublish: true,
      },
      // Inquiries
    ];

    // include vendor badge section for published storefronts
    if (isPublished && badgesSection) {
      sections.unshift(badgesSection);
    }

    return sections.map((block) => {
      return { ...defaultProps, ...block };
    });
  }
);

const getStorefrontSectionsPhotographer = createSelector(
  getBadgesSection,
  getCoverImagesSection,
  getBasicInfoSection,
  getVendorPricingSection,
  getAnnouncementsSection,
  getPortfolioSection,
  getVideoSection,
  getBioSection,
  getMarketSection,
  getRealWeddingsSubmissionSection,
  getServicesSection,
  getPackagesSection,
  getPreferredVendorsSection,
  getReviewsSection,
  getFaqSection,
  getIsPublished,
  (
    badgesSection,
    coverImageSection,
    basicInfoSection,
    vendorPricingSection,
    announcementsSection,
    portfolioSection,
    videoSection,
    bioSection,
    marketSection,
    realWeddingsSection,
    servicesSection,
    packagesSection,
    perferredVendorsSection,
    reviewsSection,
    faqSection,
    isPublished
  ): StorefrontSections[] => {
    const defaultProps = {
      requiredToPublish: false,
      hasContent: false,
    };

    // restrict some steps to published storefronts
    const sections = isPublished
      ? [
          badgesSection, // published only
          coverImageSection,
          basicInfoSection,
          vendorPricingSection,
          announcementsSection,
          portfolioSection,
          videoSection,
          bioSection,
          marketSection,
          realWeddingsSection, // published only
          servicesSection,
          packagesSection,
          perferredVendorsSection,
          reviewsSection,
          faqSection,
        ]
      : [
          coverImageSection,
          basicInfoSection,
          vendorPricingSection,
          portfolioSection,
          videoSection,
          bioSection,
          marketSection,
          servicesSection,
          packagesSection,
          perferredVendorsSection,
          reviewsSection,
          faqSection,
        ];

    return sections.filter(isStorefrontSection).map((block) => {
      return { ...defaultProps, ...block };
    });
  }
);

const getStorefrontSectionsVideographer = createSelector(
  getBadgesSection,
  getCoverImagesSection,
  getBasicInfoSection,
  getVendorPricingSection,
  getAnnouncementsSection,
  getVideoSection,
  getBioSection,
  getMarketSection,
  getVideoGallerySection,
  getServicesSection,
  getPackagesSection,
  getPreferredVendorsSection,
  getReviewsSection,
  getFaqSection,
  getIsPublished,
  (
    badgesSection,
    coverImageSection,
    basicInfoSection,
    vendorPricingSection,
    announcementsSection,
    videoSection,
    bioSection,
    marketSection,
    videoGallerySection,
    servicesSection,
    packagesSection,
    perferredVendorsSection,
    reviewsSection,
    faqSection,
    isPublished
  ) => {
    const defaultProps = {
      requiredToPublish: false,
      hasContent: false,
    };

    const sections = [
      coverImageSection,
      basicInfoSection,
      vendorPricingSection,
      ...(announcementsSection ? [announcementsSection] : []),
      videoSection,
      bioSection,
      marketSection,
      videoGallerySection,
      servicesSection,
      packagesSection,
      perferredVendorsSection,
      reviewsSection,
      faqSection,
    ];

    // include vendor badge section for published storefronts
    if (isPublished && badgesSection) {
      sections.unshift(badgesSection);
    }

    return sections.map((block) => {
      return { ...defaultProps, ...block };
    });
  }
);

const getStorefrontSectionsFlorist = createSelector(
  getBadgesSection,
  getCoverImagesSection,
  getBasicInfoSection,
  getVendorPricingSection,
  getAnnouncementsSection,
  getPortfolioSection,
  getBioSection,
  getMarketSection,
  getServicesSection,
  getPackagesSection,
  getPreferredVendorsSection,
  getReviewsSection,
  getFaqSection,
  getIsPublished,
  (
    badgesSection,
    coverImageSection,
    basicInfoSection,
    vendorPricingSection,
    announcementsSection,
    portfolioSection,
    bioSection,
    marketSection,
    servicesSection,
    packagesSection,
    perferredVendorsSection,
    reviewsSection,
    faqSection,
    isPublished
  ) => {
    const defaultProps = {
      requiredToPublish: false,
      hasContent: false,
    };

    const sections = [
      coverImageSection,
      basicInfoSection,
      vendorPricingSection,
      ...(announcementsSection ? [announcementsSection] : []),
      portfolioSection,
      bioSection,
      marketSection,
      servicesSection,
      packagesSection,
      perferredVendorsSection,
      reviewsSection,
      faqSection,
    ];

    // include vendor badge section for published storefronts
    if (isPublished && badgesSection) {
      sections.unshift(badgesSection);
    }

    return sections.map((block) => {
      return { ...defaultProps, ...block };
    });
  }
);

const getStorefrontSectionsBeautician = createSelector(
  getBadgesSection,
  getCoverImagesSection,
  getBasicInfoSection,
  getVendorPricingSection,
  getAnnouncementsSection,
  getPortfolioSection,
  getBioSection,
  getMarketSection,
  getServicesSection,
  getPackagesSection,
  getPreferredVendorsSection,
  getReviewsSection,
  getFaqSection,
  getIsPublished,
  (
    badgesSection,
    coverImageSection,
    basicInfoSection,
    vendorPricingSection,
    announcementsSection,
    portfolioSection,
    bioSection,
    marketSection,
    servicesSection,
    packagesSection,
    perferredVendorsSection,
    reviewsSection,
    faqSection,
    isPublished
  ) => {
    const defaultProps = {
      requiredToPublish: false,
      hasContent: false,
    };

    const sections = [
      coverImageSection,
      basicInfoSection,
      vendorPricingSection,
      ...(announcementsSection ? [announcementsSection] : []),
      portfolioSection,
      bioSection,
      marketSection,
      packagesSection,
      servicesSection,
      perferredVendorsSection,
      reviewsSection,
      faqSection,
    ];

    // include vendor badge section for published storefronts
    if (isPublished && badgesSection) {
      sections.unshift(badgesSection);
    }

    return sections.map((block) => {
      return { ...defaultProps, ...block };
    });
  }
);

const getStorefrontSectionsCaterer = createSelector(
  getBadgesSection,
  getCoverImagesSection,
  getBasicInfoSection,
  getVendorPricingSection,
  getAnnouncementsSection,
  getPortfolioSection,
  getBioSection,
  getMarketSection,
  getServicesSection,
  getStoriesSection,
  getPreferredVendorsSection,
  getReviewsSection,
  getFaqSection,
  getIsPublished,
  getIsBarServicesOnly,
  getPackagesSection,
  (
    badgesSection,
    coverImageSection,
    basicInfoSection,
    vendorPricingSection,
    announcementsSection,
    portfolioSection,
    bioSection,
    marketSection,
    servicesSection,
    storiesSection,
    perferredVendorsSection,
    reviewsSection,
    faqSection,
    isPublished,
    barServicesOnly,
    packagesSection
  ): StorefrontSections[] => {
    const defaultProps = {
      requiredToPublish: false,
      hasContent: false,
    };

    const sections = [
      coverImageSection,
      basicInfoSection,
      vendorPricingSection,
      announcementsSection,
      portfolioSection,
      {
        ...bioSection,
        completionContribution: 5.0,
      },
      marketSection,
      {
        ...servicesSection,
        title: 'Services Provided',
      },
      barServicesOnly ? packagesSection : null,
      barServicesOnly ? null : storiesSection,
      perferredVendorsSection,
      reviewsSection,
      faqSection,
    ].filter(isStorefrontSection);

    // include vendor badge section for published storefronts
    if (isPublished && badgesSection) {
      sections.unshift(badgesSection);
    }

    return sections.map((block) => {
      return { ...defaultProps, ...block };
    });
  }
);

const getStorefrontSectionsMusician = createSelector(
  getBadgesSection,
  getCoverImagesSection,
  getBasicInfoSection,
  getVendorPricingSection,
  getAnnouncementsSection,
  getVideoSection,
  getBioSection,
  getMarketSection,
  getVideoGallerySection,
  getPackagesSection,
  getPortfolioSection,
  getPreferredVendorsSection,
  getReviewsSection,
  getFaqSection,
  getIsPublished,
  getServicesSection,
  (
    badgesSection,
    coverImageSection,
    basicInfoSection,
    vendorPricingSection,
    announcementsSection,
    videoSection,
    bioSection,
    marketSection,
    videoGallerySection,
    packagesSection,
    portfolioSection,
    perferredVendorsSection,
    reviewsSection,
    faqSection,
    isPublished,
    servicesSection
  ) => {
    const defaultProps = {
      requiredToPublish: false,
      hasContent: false,
      servicesSection,
    };

    const sections = [
      coverImageSection,
      basicInfoSection,
      vendorPricingSection,
      ...(announcementsSection ? [announcementsSection] : []),
      videoSection,
      videoGallerySection,
      {
        ...bioSection,
        completionContribution: 5.0,
      },
      marketSection,
      portfolioSection,
      packagesSection,
      servicesSection,

      {
        ...faqSection,
        requiredToPublish: false,
      },
      reviewsSection,
      perferredVendorsSection,
    ];

    // include vendor badge section for published storefronts
    if (isPublished && badgesSection) {
      sections.unshift(badgesSection);
    }

    return sections.map((block) => {
      return { ...defaultProps, ...block };
    });
  }
);

const getStorefrontSectionsBaker = createSelector(
  getBadgesSection,
  getCoverImagesSection,
  getBasicInfoSection,
  getVendorPricingSection,
  getAnnouncementsSection,
  getPortfolioSection,
  getBioSection,
  getMarketSection,
  getPackagesSection,
  getFaqSection,
  getReviewsSection,
  getPreferredVendorsSection,
  getIsPublished,
  getServicesSection,
  (
    badgesSection,
    coverImageSection,
    basicInfoSection,
    vendorPricingSection,
    announcementsSection,
    portfolioSection,
    bioSection,
    marketSection,
    packagesSection,
    faqSection,
    reviewsSection,
    perferredVendorsSection,
    isPublished,
    servicesSection
  ) => {
    const defaultProps = {
      requiredToPublish: false,
      hasContent: false,
      servicesSection,
    };

    const sections = [
      coverImageSection,
      basicInfoSection,
      vendorPricingSection,
      ...(announcementsSection ? [announcementsSection] : []),
      portfolioSection,
      {
        ...bioSection,
        completionContribution: 15.0,
      },
      marketSection,
      packagesSection,
      servicesSection,
      faqSection,
      reviewsSection,
      perferredVendorsSection,
    ];

    // include vendor badge section for published storefronts
    if (isPublished && badgesSection) {
      sections.unshift(badgesSection);
    }

    return sections.map((block) => {
      return { ...defaultProps, ...block };
    });
  }
);

const getStorefrontSectionsWeddingPlanners = createSelector(
  getBadgesSection,
  getCoverImagesSection,
  getBasicInfoSection,
  getMarketSection,
  getServiceLevelsAndPricingSection,
  getAnnouncementsSection,
  getBioSection,
  getReviewsSection,
  getPreferredVendorsSection,
  getFaqSection,
  getPortfolioSection,
  getIsPublished,
  (
    badgesSection,
    coverImageSection,
    basicInfoSection,
    marketSection,
    serviceLevelsAndPricingSection,
    announcementsSection,
    bioSection,
    reviewsSection,
    perferredVendorsSection,
    faqSection,
    photoGallery,
    isPublished
  ) => {
    const defaultProps = {
      requiredToPublish: false,
      hasContent: false,
    };

    const plannerSections = [
      coverImageSection,
      basicInfoSection,
      marketSection,
      photoGallery,
      serviceLevelsAndPricingSection,
      ...(announcementsSection ? [announcementsSection] : []),
      bioSection,
      reviewsSection,
      perferredVendorsSection,
      faqSection,
    ];

    // include vendor badge section for published storefronts
    if (isPublished && badgesSection) {
      plannerSections.unshift(badgesSection);
    }

    return plannerSections.map((block) => {
      return { ...defaultProps, ...block };
    });
  }
);

// TODO: when this is ts converted visit getPercentageCompleteContributors and try to remove the @ts-ignore

export const getStorefrontSections = createSelector(
  getVendorTaxonomyKey,
  getStorefrontSectionsVenue,
  getStorefrontSectionsPhotographer,
  getStorefrontSectionsVideographer,
  getStorefrontSectionsFlorist,
  getStorefrontSectionsCaterer,
  getStorefrontSectionsMusician,
  getStorefrontSectionsBeautician,
  getStorefrontSectionsBaker,
  getStorefrontSectionsWeddingPlanners,
  (
    taxonomyKey,
    venueSections,
    photographerSections,
    videograherSections,
    floristSections,
    catererSections,
    musicianSections,
    beauticianSections,
    bakerSections,
    weddingPlanners
  ) => {
    return getVendorTaxonomySwitchFunc({
      [VENUES_TAXONOMY_KEY]: venueSections,
      [PHOTOGRAPHERS_TAXONOMY_KEY]: photographerSections,
      [VIDEOGRAPHERS_TAXONOMY_KEY]: videograherSections,
      [FLORISTS_TAXONOMY_KEY]: floristSections,
      [CATERING_TAXONOMY_KEY]: catererSections,
      [BAR_SERVICES_TAXONOMY_KEY]: catererSections,
      [BANDS_DJS_TAXONOMY_KEY]: musicianSections,
      [HAIR_MAKEUP_TAXONOMY_KEY]: beauticianSections,
      [CAKES_DESSERTS_TAXONOMY_KEY]: bakerSections,
      [PLANNERS_TAXONOMY_KEY]: weddingPlanners,
    })(taxonomyKey);
  }
);

const getPricingDefinitionSection = createSelector(
  getIsDefinePricingSectionComplete,
  getIsDefinePricingSectionInProgress,
  (isDefinePricingSectionComplete, isInProgress): StorefrontSections => {
    return {
      key: 'define-pricing',
      requiredToPublish: true,
      linkTo: '/inspire/vendors/listing/pricing/define',
      hasContent: isDefinePricingSectionComplete,
      title: 'Define Your Pricing',

      completionContribution: 0,
      isInProgress,
    };
  }
);
const getAverageSpendSection = createSelector(
  getIsAverageSpendSectionComplete,
  getIsAverageSpendSectionInProgress,
  (isAverageSpendSectionComplete, isInProgress): StorefrontSections => {
    return {
      key: 'average-spend',
      requiredToPublish: true,
      linkTo: '/inspire/vendors/listing/pricing/average-spend',
      hasContent: isAverageSpendSectionComplete,
      title: 'Your Couples’ Average Spend',
      customCtaId: 'Your Couples Average Spend',

      completionContribution: 0,
      isInProgress,
    };
  }
);

export const getPricingSections = createSelector(
  getVendorTaxonomyKey,
  getExtraCostsSection,
  getPricingDefinitionSection,
  getAverageSpendSection,
  (taxonomyKey, extraCosts, vendorPricing, averageSpend): StorefrontSections[] => {
    switch (taxonomyKey) {
      case CATERING_TAXONOMY_KEY:
      case BAR_SERVICES_TAXONOMY_KEY:
        return [vendorPricing, averageSpend, extraCosts];
      default: {
        return [vendorPricing, averageSpend];
      }
    }
  }
);
function isStorefrontSection(section: StorefrontSections | null): section is StorefrontSections {
  return !!section;
}

/**
 * Wedding Planners Service and Levels Pricing
 */

const getFullServicePlanningSection = createSelector(
  (state: RootState) => getIsPlanningLevelComplete(state, 'planner-service-level-full-service'),
  (publishStatus) => {
    return {
      key: 'planner-service-level-full-service',
      requiredToPublish: publishStatus,

      linkTo:
        '/inspire/vendors/listing/planning-levels-and-pricing/planner-service-level-full-service',

      title: 'Full-Service Planning',

      completionContribution: 0,
    };
  }
);

const getPartialPlanningSection = createSelector(
  (state: RootState) => getIsPlanningLevelComplete(state, 'planner-service-level-partial-planning'),
  (publishStatus) => {
    return {
      key: 'planner-service-level-partial-planning',
      requiredToPublish: publishStatus,

      linkTo:
        '/inspire/vendors/listing/planning-levels-and-pricing/planner-service-level-partial-planning',
      title: 'Partial Planning',

      completionContribution: 0,
    };
  }
);

const getDayOfCoordinationSection = createSelector(
  (state: RootState) =>
    getIsPlanningLevelComplete(state, 'planner-service-level-day-of-coordination'),
  (publishStatus) => {
    return {
      key: 'planner-service-level-day-of-coordination',
      requiredToPublish: publishStatus,

      linkTo:
        '/inspire/vendors/listing/planning-levels-and-pricing/planner-service-level-day-of-coordination',

      title: 'Day-of Coordination',

      completionContribution: 0,
    };
  }
);

const getDestinationWeddingSection = createSelector(
  (state: RootState) =>
    getIsPlanningLevelComplete(state, 'planner-service-level-destination-wedding'),
  (publishStatus) => {
    return {
      key: 'planner-service-level-destination-wedding',
      requiredToPublish: publishStatus,

      linkTo:
        '/inspire/vendors/listing/planning-levels-and-pricing/planner-service-level-destination-wedding',

      title: 'Destination Wedding',

      completionContribution: 0,
    };
  }
);

const getFullDesignEventSection = createSelector(
  (state: RootState) => getIsPlanningLevelComplete(state, 'planner-service-level-event-design'),
  (publishStatus) => {
    return {
      key: 'planner-service-level-event-design',
      requiredToPublish: publishStatus,

      linkTo:
        '/inspire/vendors/listing/planning-levels-and-pricing/planner-service-level-event-design',

      title: 'Full Event Design',

      completionContribution: 0,
    };
  }
);
const getElopementSection = createSelector(
  (state: RootState) => getIsPlanningLevelComplete(state, 'planner-service-level-elopement'),
  (publishStatus) => {
    return {
      key: 'planner-service-level-elopement',
      requiredToPublish: publishStatus,

      linkTo:
        '/inspire/vendors/listing/planning-levels-and-pricing/planner-service-level-elopement',
      title: 'Elopement/Micro Wedding',

      completionContribution: 0,
    };
  }
);

const getAlaCarteSection = createSelector(
  (state: RootState) => getIsPlanningLevelComplete(state, 'planner-service-level-a-la-carte'),
  (publishStatus) => {
    return {
      key: 'planner-service-level-a-la-carte',
      requiredToPublish: publishStatus,

      linkTo:
        '/inspire/vendors/listing/planning-levels-and-pricing/planner-service-level-a-la-carte',
      title: 'A la Carte Planning',

      completionContribution: 0,
    };
  }
);

export const getPlanningLevelAndPricingSection = createSelector(
  getFullServicePlanningSection,
  getPartialPlanningSection,
  getDayOfCoordinationSection,
  getDestinationWeddingSection,
  getFullDesignEventSection,
  getElopementSection,
  getAlaCarteSection,
  (
    fullServicePlanning,
    partialPlanningSection,
    dayOfCoordinationSection,
    destinationWeddingSection,
    fullEventDesign,
    elopementSection,
    alaCarteSection
  ) => {
    return [
      fullServicePlanning,
      partialPlanningSection,
      dayOfCoordinationSection,
      destinationWeddingSection,
      fullEventDesign,
      elopementSection,
      alaCarteSection,
    ];
  }
);
