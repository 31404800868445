import {
  BANDS_DJS_TAXONOMY_KEY,
  CAKES_DESSERTS_TAXONOMY_KEY,
  CATERING_TAXONOMY_KEY,
  FLORISTS_TAXONOMY_KEY,
  PHOTOGRAPHERS_TAXONOMY_KEY,
  VENUES_TAXONOMY_KEY,
  VIDEOGRAPHERS_TAXONOMY_KEY,
  HAIR_MAKEUP_TAXONOMY_KEY,
  BAR_SERVICES_TAXONOMY_KEY,
  PLANNERS_TAXONOMY_KEY,
} from '@zola-helpers/client/dist/es/marketplace/vendorTaxonomyKeys';
import { getVendorTaxonomyKey } from '@zola-helpers/client/dist/es/marketplace/vendorUtils';

import _get from 'lodash/get';

import baker from './baker';
import barServices from './barServices';
import beautician from './beautician';
import caterer from './caterer';
import florist from './florist';
import musician from './musician';
import photographer from './photographer';
import planner from './planner';
import venue from './venue';
import videographer from './videographer';

/**
 *
 * @param {string} vendorType
 * @param {string} key
 * Takes a vendor type string and a dot-separated key, i.e. "bioForm.header"
 * and returns the proper copy associated with that vendor type
 */
export default function t(vendorType: string, key: string, defaultString = ''): string {
  const dictionaryLookup = () => {
    switch (getVendorTaxonomyKey(vendorType)) {
      case BANDS_DJS_TAXONOMY_KEY:
        return musician;
      case BAR_SERVICES_TAXONOMY_KEY:
        return barServices;
      case CAKES_DESSERTS_TAXONOMY_KEY:
        return baker;
      case CATERING_TAXONOMY_KEY:
        return caterer;
      case FLORISTS_TAXONOMY_KEY:
        return florist;
      case HAIR_MAKEUP_TAXONOMY_KEY:
        return beautician;
      case PHOTOGRAPHERS_TAXONOMY_KEY:
        return photographer;
      case PLANNERS_TAXONOMY_KEY:
        return planner;
      case VENUES_TAXONOMY_KEY:
        return venue;
      case VIDEOGRAPHERS_TAXONOMY_KEY:
        return videographer;
      default:
        return {};
    }
  };

  const vendorDictionary = dictionaryLookup();
  return _get(vendorDictionary, key, defaultString);
}
