import Calendar from '@zola/zola-ui/src/assets/images/icons/calendar.svg';

import { VendorTerms } from '~/types/types';

const plannerTerms: VendorTerms = {
  vendorType: {
    singular: 'wedding-planner',
    plural: 'wedding-planners',
  },
  introSection: {
    sectionTitle: 'Learn More',
  },
  editBioSection: {
    descriptionFieldLabel: 'About Your Business Or You',
    descriptionFieldPlaceholder:
      "Tell couples about your business or yourself and why you're passionate about your work.",
    nameField: 'Your Name or Team Name',
    nameFieldPlaceholder: 'Example: John Smith or Our Team',
    setsMeApartPlaceholder: 'planners',
    titleField: 'Example: Head Planner',
    actSizeLabel: 'Team Size (Number of Planners)',
  },
  inquiriesV2: {
    services: {
      hed: "They're looking to discuss these",
      icon: Calendar,
    },
    venue: {
      proTip:
        'Planners love to know the space they‘re working with early, especially for day-of coordination.',
    },
  },
};

export default plannerTerms;
