import _isEmpty from 'lodash/isEmpty';

import type { RootState } from '~/reducers';

export const getIsBasicInfoInProgress = (state: RootState) => {
  const { name, address } = state.vendorStorefront.storefrontDetails || {};

  return !_isEmpty(name) || !_isEmpty(address?.city) || !_isEmpty(address?.stateProvince);
};

export const getIsBasicInfoComplete = (state: RootState) => {
  const { name, address } = state.vendorStorefront.storefrontDetails || {};

  return !_isEmpty(name) && !_isEmpty(address?.city) && !_isEmpty(address?.stateProvince);
};
