import { VendorTerms } from '~/types/types';

const photographerTerms: VendorTerms = {
  vendorType: {
    singular: 'photographer',
    plural: 'photographers',
  },
  editBioSection: {
    descriptionFieldLabel: 'About Your Business Or You',
    descriptionFieldPlaceholder:
      "Tell couples about your business or yourself and why you're passionate about your work.",
    titleField: 'Example: Head Photographer',
    nameField: 'Your Name or Team Name',
    nameFieldPlaceholder: 'Example: John Smith or Our Team',
  },
};

export default photographerTerms;
