import { VendorTerms } from '~/types/types';

const catererTerms: VendorTerms = {
  vendorType: {
    singular: 'caterer',
    plural: 'caterers',
  },
  editBioSection: {
    descriptionFieldLabel: 'About Your Business Or You',
    descriptionFieldPlaceholder:
      "Tell couples about your business or yourself and why you're passionate about your work.",
    titleField: 'Example: Lead Chef or Culinary Director',
    setsMeApartPlaceholder: 'caterers',
    nameField: 'Your Name or Team Name',
    nameFieldPlaceholder: 'Example: John Smith or Our Team',
  },
  editServicesSection: {
    title: 'Services You Provide',
  },
};

export default catererTerms;
