import { VendorTerms } from '~/types/types';

const floristTerms: VendorTerms = {
  vendorType: {
    singular: 'florist',
    plural: 'florists',
  },
  introSection: {
    sectionTitle: 'Behind The Bouquet',
  },
  gallerySection: {
    openCta: 'See All Photos',
    closeCta: 'See Less',
  },
  editServicesSection: {
    title: 'Services & Arrangement Types You Provide',
  },
  editBioSection: {
    descriptionFieldLabel: 'About Your Business Or You',
    descriptionFieldPlaceholder:
      "Tell couples about your business or yourself and why you're passionate about your work.",
    titleField: 'Example: Lead Florist or Creative Director',
    nameField: 'Your Name or Team Name',
    nameFieldPlaceholder: 'Example: John Smith or Our Team',
  },
};

export default floristTerms;
