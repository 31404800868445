import { VendorTerms } from '~/types/types';

const barServicesTerms: VendorTerms = {
  inquiriesV2: {
    shortName: 'Beverage Provider',
    budget: {
      hed: 'How much do you imagine spending on your Bar Services & Beverage provider?',
    },

    priceStage: {
      hed: 'Where are you in the process of finding a Bar Services & Beverage provider?',
    },
  },
};

export default barServicesTerms;
