import { VendorTerms } from '~/types/types';

const bakerTerms: VendorTerms = {
  vendorType: {
    singular: 'baker',
    plural: 'bakers',
  },
  editBioSection: {
    descriptionFieldLabel: 'About Your Business Or You',
    descriptionFieldPlaceholder:
      "Tell couples about your business or yourself and why you're passionate about your work.",
    titleField: 'Example: Master Baker',
    setsMeApartPlaceholder: 'bakers',
    nameFieldPlaceholder: 'Example: Your Name or Company Name',
  },
  inquiriesV2: {
    budget: {
      hed: "What's your estimated cake and dessert budget?",
    },
    venue: {
      proTip:
        'Bakers often prefer you reach out after booking a venue so they have a confirmed date and delivery location, if applicable.',
    },
  },
};

export default bakerTerms;
