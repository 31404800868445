import { ChangeEvent } from 'react';

export const numberToFormValue = (value: number | null): string => `${value ?? ''}`;

/**
 * Converts a form value to a number or null, which is what we use outside the form
 */
export const formValueToNumber = (value: number | string | undefined | null): number | null =>
  parseInt((value || '') as string, 10) || null;

/**
 * Change event handler to auto-adjust the height of an input to fit its content
 *
 * Use CSS `max-height` to limit the height
 */
export const autoAdjustHeight = (event: ChangeEvent<HTMLInputElement>) => {
  const { scrollHeight, clientHeight, value } = event.target;
  if (scrollHeight > clientHeight && scrollHeight < 1000) {
    event.target.style.height = `${scrollHeight}px`;
  } else if (scrollHeight === clientHeight && value === '') {
    event.target.style.height = '';
  }
};
