import { createSelector } from 'reselect';

import type { RootState } from '~/reducers';

const getReviewsOfType = (state: RootState, type: 'vendor' | 'couple'): unknown[] =>
  (state.vendorStorefront.storefrontDetails?.reviews || []).filter(
    (review) => review.reviewerType.toLowerCase() === type
  );

const getVendorReviews = (state: RootState) => getReviewsOfType(state, 'vendor');

const getCouplesReviews = (state: RootState) => getReviewsOfType(state, 'couple');

export const getAreReviewRequestsComplete = createSelector(
  getVendorReviews,
  getCouplesReviews,
  (vendorReviews, couplesReviews): boolean => vendorReviews.length + couplesReviews.length >= 4
);
