import type { ResponseType, ErrorType } from '@zola-helpers/client/dist/es/http/types';
import {
  AddressValidationView,
  ApplyFacetOptionsRequest,
  CreateAddressRequest,
  CreateVenueSpaceRequest,
  ReorderVenueSpacesRequest,
  UpdateStorefrontRequest,
  UpdateTargetBudgetRequest,
  UpdateVenueRequest,
  UpdateVenueSpaceRequest,
} from '@zola/svc-marketplace-ts-types';

import _get from 'lodash/get';
import { SnakeCasedPropertiesDeep } from 'type-fest/index.d';

import Facets from '~/meta/facets';
import type { AppThunk } from '~/reducers';
import type { MappedAddressView } from '~/types/mappedResponseTypes';
import type { MappedOptionFacetView, VenueDetails, VenueSpaceView } from '~/types/responseTypes';
import type {
  CouplesStorefrontDetailsVenue,
  MappedStorefrontDetails,
  VendorStorefrontDetails,
} from '~/types/storefrontDetails';
import { formValueToNumber } from '~/util/formUtils';
import { dollarsToCents } from '~/util/priceConversion';
import { VENDOR_SUPPORT_EMAIL } from '~/util/vendorSupportEmail';

import ApiService from '../../util/apiService';
import Logger from '../../util/logger';
import * as NotificationsActions from '../notificationActions';
import * as ActionType from './types/vendorStorefrontActionTypes';

const ADDRESS_VALIDATION_ERROR =
  'Error saving your listing. Please check your address and try again.';

type StorefrontUpdate = SnakeCasedPropertiesDeep<
  // vendorMarketIds are optional (no update is made if they aren't provided) and we update markets with a separate endpoint
  // Updated at is used to make sure the data is _fresh_ but we've not been sending that field (its not in our mapped storefronts)
  // Onboarding Version has never been used
  // availableThrough can be a date
  Omit<
    UpdateStorefrontRequest,
    'vendorMarketIds' | 'updatedAt' | 'onboardingVersion' | 'availableThrough'
  > & {
    availableThrough: Date | null | number;
  }
>;

const updateVendorStoreFront = (
  uuid: string,
  data: StorefrontUpdate
): AppThunk<Promise<MappedStorefrontDetails | void>> => {
  return (dispatch) => {
    dispatch(ActionType.updatingStorefront());
    return (
      ApiService.put<MappedStorefrontDetails, StorefrontUpdate>(
        `/web-marketplace-api/v1/vendor-storefront/${uuid}`,
        data
      )
        .then((mappedStorefront) => {
          dispatch(ActionType.updatedStorefront(mappedStorefront));
          return mappedStorefront;
        })
        // This type might not be exactly right
        .catch((error: ErrorType<ResponseType>) => {
          Logger.error(error.message, error);
          const reason = _get(error, 'response.error.category.reason', null);
          if (reason === 'address-validation-error') {
            dispatch(
              NotificationsActions.error({
                message: ADDRESS_VALIDATION_ERROR,
              })
            );
          } else {
            dispatch(NotificationsActions.error({ message: 'Error saving your listing.' }));
          }
          dispatch(ActionType.failedToSaveStorefront(error.message));
        })
    );
  };
};

const validateVendorAddress = (
  uuid: string,
  data: StorefrontUpdate
): AppThunk<Promise<MappedStorefrontDetails | void>> => {
  return (dispatch) => {
    return ApiService.post<
      AddressValidationView,
      CreateAddressRequest | SnakeCasedPropertiesDeep<CreateAddressRequest>
    >('/web-marketplace-api/v2/address/validate', data.address, {})
      .then((response) => {
        if (
          response &&
          ['FULL_ADDRESS', 'CITY_STATE_ZIP', 'CITY_STATE'].includes(response.validationResult)
        ) {
          return dispatch(updateVendorStoreFront(uuid, data));
        }

        throw new Error(ADDRESS_VALIDATION_ERROR);
      })
      .catch((error) => {
        Logger.error(error.message, error);
        dispatch(
          NotificationsActions.error({
            message: ADDRESS_VALIDATION_ERROR,
          })
        );
        dispatch(ActionType.failedToSaveStorefront(error.message));
      });
  };
};

const convertPrice = (value: number | null): number | null => {
  return dollarsToCents(formValueToNumber(value));
};
/**
 * Update storefront by uuid.
 *
 * Everything must be included or it will null the field.
 */
export function updateStorefront(
  storefront: VendorStorefrontDetails | CouplesStorefrontDetailsVenue,
  update: Omit<Partial<VendorStorefrontDetails>, 'address' | 'availableThrough'> & {
    address?: Partial<MappedAddressView>;
    availableThrough?: Date | null | number;
  },
  updateAddress = true
): AppThunk<Promise<MappedStorefrontDetails | void>> {
  return (dispatch) => {
    const combinedStorefront = { ...storefront, ...update };

    const body: StorefrontUpdate = {
      name: combinedStorefront.name,
      headline: combinedStorefront.headline,
      description: combinedStorefront.description,
      claimed_at: combinedStorefront.claimedAt,
      slug: combinedStorefront.slug,
      closed_at: combinedStorefront.closedAt,
      hashtag: combinedStorefront.hashtag,
      peak_start_price_cents: convertPrice(combinedStorefront.peakStartPrice),
      peak_end_price_cents: convertPrice(combinedStorefront.peakEndPrice),
      off_peak_start_price_cents: convertPrice(combinedStorefront.offPeakStartPrice),
      off_peak_end_price_cents: convertPrice(combinedStorefront.offPeakEndPrice),

      // These fields are _not_ mapped.  Its less work to not map them.
      full_start_price_cents: combinedStorefront.fullStartPriceCents,
      full_end_price_cents: combinedStorefront.fullEndPriceCents,

      target_couple_budget_min_cents: convertPrice(combinedStorefront.targetCoupleBudgetMin),
      target_couple_budget_max_cents: convertPrice(combinedStorefront.targetCoupleBudgetMax),
      target_per_person_budget_min_cents: convertPrice(combinedStorefront.targetPerPersonBudgetMin),
      target_per_person_budget_max_cents: convertPrice(combinedStorefront.targetPerPersonBudgetMax),
      price_range_visible: combinedStorefront.priceRangeVisible,
      insured: combinedStorefront.insured,
      licensed: combinedStorefront.licensed,
      // This is always null
      verified_at: combinedStorefront.verifiedAt as number | null,
      status: combinedStorefront.status,
      /** @ts-expect-error The date part of this is confusing tS */
      available_through: combinedStorefront.availableThrough,
      sets_me_apart: combinedStorefront.setsMeApart,
      address: null,
      phone: combinedStorefront.phone ?? null,
    };

    if (updateAddress) {
      body.address = {
        uuid: combinedStorefront.address.uuid ?? null,
        address1: combinedStorefront.address.address1 ?? null,
        address2: combinedStorefront.address.address2 ?? null,
        city: combinedStorefront.address.city ?? null,
        state_province: combinedStorefront.address.stateProvince ?? null,
        postal_code: combinedStorefront.address.postalCode ?? null,
        country_code: combinedStorefront.address.countryCode ?? null,
        latitude: combinedStorefront.address.latitude ?? null,
        longitude: combinedStorefront.address.longitude ?? null,
        possible_city_slug: combinedStorefront.address.possibleCitySlug ?? null,
        us_county_id: combinedStorefront.address.usCountyId ?? null,

        // These are all blank strings or null
        google_maps_place_id: combinedStorefront.address.googleMapsPlaceId ?? null,
      };
      return dispatch(validateVendorAddress(storefront.uuid, body));
    }

    return dispatch(updateVendorStoreFront(storefront.uuid, body));
  };
}

type UpdateTargetBudgetRequestDollars = {
  targetCoupleBudgetMax: number | null;
  targetCoupleBudgetMin: number | null;
  targetPerPersonBudgetMax: number | null;
  targetPerPersonBudgetMin: number | null;
};

export const updateStorefrontTargetBudget = (
  storefrontUuid: string,
  {
    targetCoupleBudgetMin,
    targetCoupleBudgetMax,
    targetPerPersonBudgetMin,
    targetPerPersonBudgetMax,
  }: UpdateTargetBudgetRequestDollars
): AppThunk<Promise<void>> => {
  const body: UpdateTargetBudgetRequest = {
    targetCoupleBudgetMinCents: dollarsToCents(targetCoupleBudgetMin),
    targetCoupleBudgetMaxCents: dollarsToCents(targetCoupleBudgetMax),
    targetPerPersonBudgetMinCents: dollarsToCents(targetPerPersonBudgetMin),
    targetPerPersonBudgetMaxCents: dollarsToCents(targetPerPersonBudgetMax),
  };
  return (dispatch) => {
    dispatch(ActionType.updatingStorefrontTargetBudget());
    return ApiService.put<MappedStorefrontDetails, UpdateTargetBudgetRequest>(
      `/web-marketplace-api/v1/vendor-storefront/${storefrontUuid}/target-budget`,
      body
    )
      .then((json) => {
        dispatch(ActionType.updatedStorefront(json));
      })
      .catch(() => {
        dispatch(
          NotificationsActions.error({
            message: `Error saving your target couple budget, please try again or contact ${VENDOR_SUPPORT_EMAIL}`,
          })
        );
      });
  };
};

/**
 * Update venue by uuid
 */
export function updateVenue(
  venue: VenueDetails,
  update: Partial<VenueDetails>
): AppThunk<Promise<VenueDetails | void>> {
  return (dispatch) => {
    dispatch(ActionType.updatingVenue());
    const combinedVenue = { ...venue, ...update };
    const body: SnakeCasedPropertiesDeep<UpdateVenueRequest> = {
      // Floorplans are not supported on venues.  They are a hot mess of legacy code
      // We have floor plans on spaces, not venues.  The only way a floor plan can be
      // added to a venue is in admin, but we've never had the right data in the front
      // end so any follow up update has just wiped out the data.  We also don't show it
      // anywhere in the front end.
      floorplan_uuid: null,
      rooms: combinedVenue.rooms,
      min_capacity: combinedVenue.minCapacity,
      max_capacity: combinedVenue.maxCapacity,
      parking_capacity: combinedVenue.parkingCapacity,
      bus_rv_parking_capacity: combinedVenue.busRvParkingCapacity,
      food_beverage_start_price_cents: combinedVenue.foodBeverageStartPriceCents,
      food_and_beverage_start_price_cents_per_person:
        combinedVenue.foodAndBeverageStartPriceCentsPerPerson,
      peak_space_start_price_cents: combinedVenue.peakSpaceStartPriceCents,
      off_peak_space_start_price_cents: combinedVenue.offPeakSpaceStartPriceCents,
    };
    return ApiService.put<VenueDetails>(
      `/web-marketplace-api/v1/vendor-storefront/${venue.uuid}/venue`,
      body
    )
      .then((json) => {
        dispatch(ActionType.updatedVenue(json));
        return json;
      })
      .catch((error) => {
        Logger.error(error.message, error);
        dispatch(NotificationsActions.error({ message: 'Error saving your listing.' }));
      });
  };
}

// The request here is coming from a redux form, where if you
// load the form from existing data, you end up with numbers
// in the form, but when you edit a field, you end up with strings.
// All the non-required values are possibly undefined, fields that
// are numbers are either strings or numbers.

export type CreateVenueSpace = {
  name: string;
  pullQuote: string;
  venueId: number;
  description: string;
  maxSeatedCapacity: string | number;
  maxStandingCapacity: string | number;
  cost: string | undefined;
  displayOrder: number | null;
  floorplanUuid: string | undefined | null; // null if you add the floorplan, then remove it
  guestsFeel: string | undefined;
  included: string | undefined;
  includedInVenueCost: boolean | undefined;
  minSeatedCapacity: string | number | null | undefined;
  minStandingCapacity: string | number | null | undefined;
  squareFeet: string | number | null | undefined;
};

export const createSpace = (space: CreateVenueSpace): AppThunk<Promise<VenueSpaceView | void>> => {
  return (dispatch) => {
    dispatch(ActionType.creatingSpace());
    const body: SnakeCasedPropertiesDeep<CreateVenueSpaceRequest> = {
      venue_id: space.venueId,
      name: space.name,
      min_seated_capacity: formValueToNumber(space.minSeatedCapacity),
      max_seated_capacity: formValueToNumber(space.maxSeatedCapacity),
      min_standing_capacity: formValueToNumber(space.minStandingCapacity),
      max_standing_capacity: formValueToNumber(space.maxStandingCapacity),
      square_feet: formValueToNumber(space.squareFeet),
      cost: space.cost ?? null,
      pull_quote: space.pullQuote,
      description: space.description,
      included: space.included ?? null,
      guests_feel: space.guestsFeel ?? null,
      floorplan_uuid: space.floorplanUuid ?? null,
      included_in_venue_cost: Boolean(space.includedInVenueCost),
      // displayOrder is set on the server
    };
    return ApiService.post<VenueSpaceView, SnakeCasedPropertiesDeep<CreateVenueSpaceRequest>>(
      '/web-marketplace-api/v1/vendor-storefront/venue/space',
      body
    )
      .then((json) => {
        dispatch(ActionType.createdSpace(json));
        return json;
      })
      .catch((error) => {
        Logger.error(error.message, error);
        dispatch(NotificationsActions.error({ message: 'Error saving your listing.' }));
      });
  };
};

export type UpdateVenueSpace = CreateVenueSpace & {
  id: number;
};
export const updateSpace = (
  venueUuid: string,
  space: UpdateVenueSpace
): AppThunk<Promise<void | VenueSpaceView>> => {
  return (dispatch) => {
    dispatch(ActionType.updatingSpace());
    const body: SnakeCasedPropertiesDeep<UpdateVenueSpaceRequest> = {
      id: space.id,
      name: space.name,
      min_seated_capacity: formValueToNumber(space.minSeatedCapacity),
      max_seated_capacity: formValueToNumber(space.maxSeatedCapacity),
      min_standing_capacity: formValueToNumber(space.minStandingCapacity),
      max_standing_capacity: formValueToNumber(space.maxStandingCapacity),
      square_feet: formValueToNumber(space.squareFeet),
      cost: space.cost ?? null,
      included_in_venue_cost: Boolean(space.includedInVenueCost),
      pull_quote: space.pullQuote,
      description: space.description,
      included: space.included ?? null,
      guests_feel: space.guestsFeel ?? null,
      floorplan_uuid: space.floorplanUuid ?? null,
      display_order: formValueToNumber(space.displayOrder),
    };
    return ApiService.put<VenueSpaceView, SnakeCasedPropertiesDeep<UpdateVenueSpaceRequest>>(
      `/web-marketplace-api/v1/vendor-storefront/venue/${venueUuid}/space`,
      body
    )
      .then((json) => {
        dispatch(ActionType.updatedSpace(json));
        return json;
      })
      .catch((error) => {
        Logger.error(error.message, error);
        dispatch(NotificationsActions.error({ message: 'Error saving your listing.' }));
      });
  };
};

type SpaceOrderUpdate = {
  id: number;
  uuid: string;
}[];

export const updateSpacesOrder = (
  venueUuid: string,
  spaces: SpaceOrderUpdate
): AppThunk<Promise<void | VenueSpaceView[]>> => {
  return (dispatch) => {
    const newOrder = spaces.map((space) => space.uuid);
    dispatch(ActionType.reorderingSpaces(newOrder));
    return ApiService.put<VenueSpaceView[], ReorderVenueSpacesRequest>(
      `/web-marketplace-api/v1/manage/venue/${venueUuid}/spaces/order`,
      {
        venueSpaceIds: spaces.map((space) => space.id),
      }
    )
      .then((updatedSpaces) => {
        dispatch(ActionType.reorderedSpaces(updatedSpaces));
        return updatedSpaces;
      })
      .catch((error) => {
        Logger.error(error.message, error);
        dispatch(NotificationsActions.error({ message: 'Error saving your listing.' }));
      });
  };
};

export const updateSpaceOptions = (
  spaceUuid: string,
  options: string[]
): AppThunk<Promise<MappedOptionFacetView[] | void>> => {
  return (dispatch) => {
    dispatch(ActionType.updatingSpaceOption());
    const body = {
      root_facet_key: Facets.EVENT_TYPES.key,
      root_facet_id: null,
      facet_keys: options,
    };
    return ApiService.post<
      MappedOptionFacetView[],
      SnakeCasedPropertiesDeep<ApplyFacetOptionsRequest>
    >(`/web-marketplace-api/v1/vendor-storefront/venue/space/${spaceUuid}/options`, body)
      .then((updatedOptions) => {
        dispatch(ActionType.updatedSpaceOption({ uuid: spaceUuid, options: updatedOptions }));
        return updatedOptions;
      })
      .catch((error) => {
        Logger.error(error.message, error);
        dispatch(NotificationsActions.error({ message: 'Error saving your listing.' }));
      });
  };
};

export const deleteSpace = (spaceUuid: string): AppThunk<Promise<VenueSpaceView | void>> => {
  return (dispatch) => {
    dispatch(ActionType.deletingSpace());
    return ApiService.delete<VenueSpaceView>(
      `/web-marketplace-api/v1/vendor-storefront/venue/space/${spaceUuid}`
    )
      .then((deletedSpace) => {
        dispatch(ActionType.deletedSpace(deletedSpace));
        return deletedSpace;
      })
      .catch((error) => {
        Logger.error(error.message, error);
        dispatch(NotificationsActions.error({ message: 'Error saving your listing.' }));
      });
  };
};
