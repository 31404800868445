import { VendorTerms } from '~/types/types';

const musicianTerms: VendorTerms = {
  vendorType: {
    singular: 'musician',
    plural: 'musicians',
  },
  editBioSection: {
    descriptionFieldLabel: 'About Your Business Or You',
    descriptionFieldPlaceholder:
      "Tell couples about your business or yourself and why you're passionate about your work.",
    titleField: 'Example: Lead Singer or Conductor',
    setsMeApartPlaceholder: 'musicians or DJs',
    nameField: 'Your Name or Team Name',
    nameFieldPlaceholder: 'Example: John Smith or Our Team',
    actSizeLabel: 'Size of Your Act',
    actSizeDjLabel: 'Number of DJs Available',
  },
  editVideoGallerySection: {
    heading: 'Give couples a closer look at your work and the types of performances you offer',
  },
  inquiriesV2: {
    venue: {
      proTip:
        "Musicians often ask that you reach out after booking a venue so they can reserve your specific date and get a feel for the space's needs.",
    },
    budget: {
      hed: 'Fun! Around how much are you budgeting for these services?',
    },
    planning: {
      hed: 'Where are you in the process of finding a musician/DJ?',
    },
  },
};

export default musicianTerms;
