import { VendorTerms } from '~/types/types';

const venueTerms: VendorTerms = {
  vendorType: {
    singular: 'venue',
    plural: 'venues',
  },
  editListingPage: {
    bioSectionTitle: 'Your Bio',
  },
};

export default venueTerms;
