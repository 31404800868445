import { VendorTerms } from '~/types/types';

const beauticianTerms: VendorTerms = {
  vendorType: {
    singular: 'beautician',
    plural: 'beauticians',
  },
  introSection: {
    sectionTitle: 'Behind the Look',
  },
  editBioSection: {
    titleField: 'Example: Owner or Lead Stylists',
    setsMeApartPlaceholder: 'stylists',
    nameFieldPlaceholder: 'Example: John Smith or Our Team',
  },
  inquiriesV2: {
    budget: {
      hed: 'Fun! Around how much are you budgeting for these services?',
    },

    planning: {
      hed: 'Where are you in the process of finding this beauty pro?',
    },
    partySize: {
      hed: 'How many people are getting glam?',
      dek: "Don't forget to count yourself, your partner, wedding party, and any other VIPs.",
    },
    venue: {
      proTip:
        'Beauty professionals often prefer you reach out after booking a venue so they have a confirmed date, time, and location.',
    },
  },
};

export default beauticianTerms;
