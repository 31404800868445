import { Fragment, PropsWithChildren } from 'react';

import { useFormState } from 'react-final-form';

import {
  getBakerMinimumServingCount,
  getBakerStartPriceCents,
  getBakerStartPricePerPersonCents,
  getBeauticianDayOfServiceStartPricePerPersonCents,
  getBeauticianMinimumServiceCount,
  getCatererAlcoholPerPersonStartPriceCents,
  getCatererMinimumHeadCount,
  getCatererMinimumSpendCents,
  getCatererStartPricePerPersonCents,
  getCurrentBeauticianDetails,
  getCurrentVenueDetails,
  getFloristBridalBouquetEndPriceCents,
  getFloristBridalBouquetStartPriceCents,
  getFloristLowCenterpieceEndPriceCents,
  getFloristLowCenterpieceStartPriceCents,
  getMusicianCeremonyStartPriceCents,
  getMusicianReceptionStartPriceCents,
  getOffPeakEndPrice,
  getOffPeakStartPrice,
  getPeakEndPrice,
  getPeakStartPrice,
} from '~/selectors/vendorStorefrontSelectors';
import { formatAsCurrency } from '~/util/textUtils';

import { calculateStartingPrice } from '../calculateStartingPrice';
import {
  InputValueSelectorType,
  PriceField,
  SingleField,
  VendorPricingFormValues,
} from '../vendorPricingFormTypes';

import styles from '../vendorPricingV2.module.less';

/**
 * Helper Text
 */

const MINIMUM_SPEND_LABEL = 'Minimum Spend';

export const HIDE_PRICE_LABEL =
  'Do not show our price info on our listing page, only use it for identifying qualified leads';
export const SHOW_PRICE_LABEL = 'Show our price info on our listing page and to qualify leads';

const DEFAULT_STARTING_PRICE_TEXT =
  'Zola uses your lowest minimum from your given price ranges for full weddings.';

/**
 * Fields for Vendor Pricing
 */

/*
/ CATERERS
*/
export const MIN_HEAD_COUNT: PriceField = {
  Component: 'SinglePriceField',
  props: {
    label: 'Minimum Head Count',
    ariaLabel: 'minimum head count',
    description: (
      <Fragment>
        What is the minimum number of servings you&apos;ll provide?
        <PricingNote>
          <li>If you have no minimum, just put 1.</li>
        </PricingNote>
      </Fragment>
    ),
    selector: getCatererMinimumHeadCount,
    name: 'minimumHeadCount',
    required: true,
    addOn: '',
  },
};

export const BEVERAGE_STARTING_PRICE: PriceField = {
  Component: 'SinglePriceField',
  props: {
    label: 'Beverage Starting Price Per Person',
    name: 'startPriceCentsPerPerson',
    description: 'What is the starting price per person for beverages?',
    required: true,
    selector: getCatererStartPricePerPersonCents,
    priceInCents: true,
  },
};

export const ALCOHOL_STARTING_PRICE: SingleField = {
  Component: 'SinglePriceField',
  props: {
    label: 'Alcohol Starting Price Per Person',
    name: 'alcoholPerPersonStartPriceCents',
    selector: getCatererAlcoholPerPersonStartPriceCents,
    description: 'What is the starting price per person for alcohol?',
    priceInCents: true,
  },
};

export const FOOD_STARTING_PRICE: SingleField = {
  Component: 'SinglePriceField',
  props: {
    name: 'startPriceCentsPerPerson',
    label: 'Food Starting Price Per Person',
    description: 'What is the starting price per person for food (excluding alcohol)?',
    ariaLabel: 'starting price per person for only food',
    required: true,
    priceInCents: true,
    selector: getCatererStartPricePerPersonCents,
  },
};

export const CATERER_MINIMUM_SPEND: PriceField = {
  Component: 'SinglePriceField',
  props: {
    name: 'startPriceCents',
    label: MINIMUM_SPEND_LABEL,
    priceInCents: true,
    selector: getCatererMinimumSpendCents,
    description: (
      <Fragment>
        What is the minimum amount you require couples to spend?
        <PricingNote>
          <li>If you have no minimum spend, leave this blank.</li>
        </PricingNote>
      </Fragment>
    ),
  },
};

/*
/* BAKERS
*/
export const BAKER_PER_SERVING_PRICE: PriceField = {
  Component: 'SinglePriceField',
  props: {
    name: 'startPriceCentsPerPerson',
    label: 'Starting Price Per Serving',
    description: 'What is your starting price per serving?',
    required: true,
    priceInCents: true,
    selector: getBakerStartPricePerPersonCents,
  },
};

export const BAKER_MIN_HEADCOUNT: PriceField = {
  Component: 'SinglePriceField',
  props: {
    name: 'minimumHeadCount',
    label: 'Minimum Serving Count',
    required: true,
    description: (
      <Fragment>
        What is the minimum number of servings you&apos;ll provide?
        <PricingNote>
          <li>If you have no minimum, just put 1.</li>
        </PricingNote>
      </Fragment>
    ),
    addOn: '',
    selector: getBakerMinimumServingCount,
  },
};

export const BAKER_MINIMUM_SPEND: PriceField = {
  Component: 'SinglePriceField',
  props: {
    name: 'minimumSpendCents',
    label: MINIMUM_SPEND_LABEL,
    priceInCents: true,
    description: (
      <Fragment>
        What is the minimum amount you require couples to spend?
        <PricingNote>
          <li>If you have no minimum spend, leave this blank. </li>
        </PricingNote>
      </Fragment>
    ),
    selector: getBakerStartPriceCents,
  },
};

/*
/* BEAUTICIANS
*/
export const BEAUTICIAN_DAY_OF_SERVICES_FIELD: PriceField = {
  Component: 'SinglePriceField',
  props: {
    name: 'startPriceCentsPerPerson',
    label: 'Day-of Services Starting Price',
    description: 'What is your starting price for day-of wedding services per person?',
    ariaLabel: 'day-of services starting price per person',
    priceInCents: true,
    required: true,
    selector: getBeauticianDayOfServiceStartPricePerPersonCents,
  },
};

export const BEAUTICIAN_MINIMUM_SPEND: PriceField = {
  Component: 'SinglePriceField',
  props: {
    name: 'startPriceCents',
    label: MINIMUM_SPEND_LABEL,
    description: (
      <Fragment>
        What is the minimum amount you require couples to spend?
        <PricingNote>
          <li>If you have no minimum spend, leave this blank.</li>
        </PricingNote>
      </Fragment>
    ),
    ariaLabel: 'minimum spend',
    priceInCents: true,
    selector: (state) => getCurrentBeauticianDetails(state)?.startPriceCents,
  },
};

export const BEAUTICIAN_MIN_SERVICE_COUNT: PriceField = {
  Component: 'SinglePriceField',
  props: {
    name: 'minimumHeadCount',
    label: 'Minimum Service Count',
    description: (
      <Fragment>
        What is the minimum number of people you&apos;ll service?
        <PricingNote>
          <li>If you have no minimum, just put 1.</li>
        </PricingNote>
      </Fragment>
    ),
    ariaLabel: 'minimum service count',
    required: true,
    addOn: '',
    selector: getBeauticianMinimumServiceCount,
  },
};

/*
/* FLORIST
*/

export const CENTERPIECE_PRICE_RANGE: PriceField = {
  Component: 'PriceRangeFields',
  props: {
    description: 'What is the price range for your centerpieces?',
    heading: 'Centerpiece Spend Price Range',
    minimum: {
      name: 'lowCenterpieceStartPriceCents',
      ariaLabel: 'Lowest starting price for centerpieces',
      required: true,
      selector: getFloristLowCenterpieceStartPriceCents,
    },
    maximum: {
      name: 'lowCenterpieceEndPriceCents',
      ariaLabel: 'Highest starting price for centerpieces',
      selector: getFloristLowCenterpieceEndPriceCents,
    },
    priceInCents: true,
  },
};

export const BOUQUET_PRICE_RANGE: PriceField = {
  Component: 'PriceRangeFields',
  props: {
    heading: 'Bouquet Spend Price Range',
    description: 'What is the price range of your bouquets?',
    minimum: {
      name: 'bridalBouquetStartPriceCents',
      ariaLabel: 'Lowest starting price for bouquets',
      required: true,
      selector: getFloristBridalBouquetStartPriceCents,
    },
    maximum: {
      name: 'bridalBouquetEndPriceCents',
      ariaLabel: 'Highest starting price for bouquets',
      selector: getFloristBridalBouquetEndPriceCents,
    },
    priceInCents: true,
  },
};

/*
/* MUSICIANS
*/

export const FULL_CEREMONY_PRICING: SingleField = {
  Component: 'SinglePriceField',
  props: {
    name: 'ceremonyStartPriceCents',
    label: 'Ceremony Starting Price',
    description: (
      <Fragment>
        What is your starting price for a ceremony (including all services)?
        <PricingNote>
          <li>If your ceremony and reception prices are the same, put the same price for both.</li>
        </PricingNote>
      </Fragment>
    ),
    priceInCents: true,
    selector: getMusicianCeremonyStartPriceCents,
  },
};

export const FULL_RECEPTION_STARTING_PRICE: SingleField = {
  Component: 'SinglePriceField',
  props: {
    name: 'receptionStartPriceCents',
    label: 'Reception Starting Price',
    description: 'What is your starting price for a reception (including all services)?',
    priceInCents: true,
    selector: getMusicianReceptionStartPriceCents,
  },
};

/*
/* VENUES
*/

export const VENUE_MIN_FOOD_AND_BEV: PriceField = {
  Component: 'SinglePriceField',
  props: {
    label: 'Food and Beverage Minimum Spend',
    name: 'foodBeverageStartPriceCents',
    ariaLabel: 'food and beverage minimum spend',
    priceInCents: true,
    description: (
      <Fragment>
        What is the minimum amount you require couples to spend on F&B, regardless of guest count?
        <PricingNote>
          <li>If you have no minimum spend, leave this blank.</li>
        </PricingNote>
      </Fragment>
    ),
    selector: (state) => getCurrentVenueDetails(state)?.foodBeverageStartPriceCents,
  },
};

export const VENUE_FOOD_AND_BEV_STARTING_PRICE: PriceField = {
  Component: 'SinglePriceField',
  props: {
    label: 'Food and Beverage Starting Price',
    name: 'foodAndBeverageStartPriceCentsPerPerson',
    description: (
      <Fragment>
        What is your starting price per person for F&B at your venue?
        <PricingNote>
          <li>If you don&apos;t offer F&B, leave this blank.</li>
        </PricingNote>
      </Fragment>
    ),
    ariaLabel: 'food and beverage per person starting price',
    selector: (state) => getCurrentVenueDetails(state)?.foodAndBeverageStartPriceCentsPerPerson,
    priceInCents: true,
  },
};

export const VENUE_RENTAL_STARTING_PRICE_OFF_PEAK: PriceField = {
  Component: 'SinglePriceField',
  props: {
    label: 'Rental Charge Starting Price (Off-peak)',
    name: 'offPeakSpaceStartPriceCents',
    description: (
      <Fragment>
        What is the off-peak starting price to rent the least expensive space/room at your venue?
        <PricingNote>
          <li>
            If your prices are consistent year round, enter the same price here and in the peak
            section.
          </li>
        </PricingNote>
      </Fragment>
    ),
    priceInCents: true,
    selector: (state) => getCurrentVenueDetails(state)?.offPeakSpaceStartPriceCents,
  },
};

export const VENUE_RENTAL_STARTING_PRICE_ON_PEAK: PriceField = {
  Component: 'SinglePriceField',
  props: {
    label: 'Rental Charge Starting Price (Peak)',
    name: 'peakSpaceStartPriceCents',
    description:
      'What is the peak starting price of renting the least expensive space/room at your venue?',
    ariaLabel: 'Rental Charge Starting Price - Peak',
    selector: (state) => getCurrentVenueDetails(state)?.peakSpaceStartPriceCents,
    priceInCents: true,
  },
};

/*
/* SHARED
*/

// This is used for both venues and any new vendor types where we forget to define pricing
export const OFF_PEAK_DEFAULT: PriceField = {
  props: {
    heading: 'Full Wedding Price Range (Off-Peak)',
    minimum: {
      name: 'offPeakStartPrice',
      required: true,
      selector: getOffPeakStartPrice,
    },
    maximum: {
      name: 'offPeakEndPrice',
      selector: getOffPeakEndPrice,
    },
    description: (
      <Fragment>
        <div>
          How much do you charge for a full wedding at your venue (including all your services) in
          off-peak season?
        </div>
        <PricingNote>
          <li>
            If your prices are consistent year round, enter the same price range here and in the
            peak section.
          </li>
        </PricingNote>
      </Fragment>
    ),
  },
  Component: 'PriceRangeFields',
};

export const PEAK_DEFAULT: PriceField = {
  Component: 'PriceRangeFields',
  props: {
    heading: 'Full Wedding Price Range (Peak)',
    minimum: {
      name: 'peakStartPrice',
      required: true,
      selector: getPeakStartPrice,
    },
    maximum: {
      name: 'peakEndPrice',
      selector: getPeakEndPrice,
    },
    description: (
      <Fragment>
        How much do you charge for a full wedding at your venue (including all your services) in
        peak season?
        <PricingNote>
          <li>
            If your prices are consistent year round, enter the same price range here as you did for
            off-peak weddings above.
          </li>
        </PricingNote>
      </Fragment>
    ),
  },
};

/**
 * This is the price for a full wedding, that is not seasonal.  For example:
 * photographers and videographers have "full wedding prices" and "elopement wedding prices"
 * but those prices are not seasonal.  Venues have a full wedding price, but those are
 * seasonal so that becomes the peak and off peak prices.
 */
export const FULL_WEDDING_PRICE: PriceField = {
  Component: 'PriceRangeFields',
  props: {
    heading: 'Full Wedding Price Range',
    description: 'What is your price range for a full wedding (including all services)?',
    minimum: {
      name: 'fullStartPriceCents',
      required: true,
      selector: (state) => state.vendorStorefront.storefrontDetails?.fullStartPriceCents,
    },
    maximum: {
      name: 'fullEndPriceCents',
      selector: (state) => state.vendorStorefront.storefrontDetails?.fullEndPriceCents,
    },
    priceInCents: true,
  },
};

export const ELOPEMENT_PRICING = (
  startSelector: InputValueSelectorType,
  endSelector: InputValueSelectorType
): PriceField => ({
  props: {
    heading: 'Elopement / Microwedding Price Range',
    description: (
      <Fragment>
        What is your price range for an elopement or microwedding (including all services)?
        <PricingNote>
          <li>
            This can be the same as your full wedding price range, if that&apos;s how you price your
            services.
          </li>
        </PricingNote>
      </Fragment>
    ),
    minimum: {
      name: 'elopementStartPriceCents',
      ariaLabel: 'min elopment price range',
      required: true,
      selector: startSelector,
    },
    maximum: {
      name: 'elopementEndPriceCents',
      ariaLabel: 'max elopment price range',
      selector: endSelector,
    },
    priceInCents: true,
  },
  Component: 'PriceRangeFields',
});

export function PricingNote({ children }: PropsWithChildren<any>) {
  return (
    <div className={styles.perPersonNotRelevant}>
      <ul>{children}</ul>
    </div>
  );
}
export const PRICING_CALCULATOR = (
  calculatedStartingPrice = DEFAULT_STARTING_PRICE_TEXT
): PriceField => ({
  Component: () => {
    const { values } = useFormState<VendorPricingFormValues>();
    const startingPrice = calculateStartingPrice(values);

    return (
      <div className={styles.pricingCalculator}>
        <span className={styles.priceCalculatorHeader}>
          <span role="img" aria-label="flying cash">
            💸
          </span>{' '}
          Calculated Starting Price
        </span>
        <div className={styles.column}>
          <div className={styles.tooltip}>{calculatedStartingPrice}</div>
          {startingPrice ? (
            <div className={styles.price} data-testid="starting-price">
              Starting Price {formatAsCurrency(startingPrice)}
            </div>
          ) : (
            <Fragment />
          )}
        </div>
      </div>
    );
  },
});
