import { createSelector } from 'reselect';

import { sortPackages } from '~/pages/vendors/Storefront/editPages/Packages/utils/packages';
import type { RootState } from '~/reducers';

import { getCurrentStorefrontId } from './vendorAccountSelectors';

const getPackagesState = (state: RootState) => state.packages;

export const getPackagesByStorefrontId = (state: RootState, storefrontId: number) => {
  const packagesState = getPackagesState(state);
  const packageIds = packagesState.storefrontToId[storefrontId] || [];
  const packages = packageIds.map((packageId) => packagesState.byId[packageId]);

  return sortPackages(packages);
};

export const getCurrentPackages = (state: RootState) => {
  const storefrontId = getCurrentStorefrontId(state);
  if (!storefrontId) {
    return null;
  }

  return getPackagesByStorefrontId(state, storefrontId);
};

export const getHasPackages = createSelector(
  getPackagesState,
  getCurrentStorefrontId,
  (packagesState, storefrontId): boolean =>
    (packagesState.storefrontToId[storefrontId || -1] || []).length > 0
);
