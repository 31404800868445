import _get from 'lodash/get';
import { createSelector } from 'reselect';

import { RootState } from '~/reducers';

export const getAllStorefrontWeddings = (state: RootState) => {
  return _get(state, 'vendors.weddings', {});
};

const getSubmittedWeddings = (state: RootState) => {
  return _get(state, `vendors.weddings.${'SUBMITTED'}`);
};

const getPublishedWeddings = (state: RootState) => {
  return _get(state, `vendors.weddings.${'PUBLISHED'}`);
};

export const getWeddingsLoaded = (state: RootState) => {
  return _get(state, `vendors.weddings.loaded`);
};

export const getAcceptedTermsByWeddingUuid = (state: RootState) => {
  return state?.vendors?.weddings?.termsAcceptedByWeddingUuid;
};

export const getAreRealWeddingsComplete = createSelector(
  getSubmittedWeddings,
  getPublishedWeddings,
  (submittedWeddings, publishedWeddings) =>
    submittedWeddings.length > 0 || publishedWeddings.length > 0
);
